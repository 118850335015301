import React from 'react'
import ProductForm from '../utils/Form/productForm';

function AdminAddProducts() {
  return (
    <ProductForm action="add"></ProductForm>
  )
}

export default AdminAddProducts
