import React, { Component } from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Collapse from '@material-ui/core/Collapse';

export class CollapseCheckbox extends Component {

  state ={
    open: false,
    checked: []
  }

  componentDidMount() {
    if(this.props.initState) {
      this.setState({
        open: this.props.initState
      })
    }
  }

  handleClick = () => {
    this.setState({open: !this.state.open})
  }

  handleAngle = () => (
    this.state.open ?
    // <FontAwesomeIcon icon={faAngleUp} className="icon"/>
    <FontAwesomeIcon icon={['fa', 'angle-up']} className="icon"/>
    :
    <FontAwesomeIcon icon={['fa', 'angle-down']} className="icon"/>
  )

  renderList = () => (
    this.props.list ?
      this.props.list.map((value)=>(
        <ListItem key={value._id} style={{padding:'10px 23px'}}>
        {/* change the horizontal value '23px' to adjust the column to match the max width of series names */}
          <ListItemText primary={value.name}/>
          <ListItemSecondaryAction>
            <Checkbox color="primary" onChange={this.handleToggle(value._id)} checked={this.state.checked.indexOf(value._id) !== -1}>
            </Checkbox>
          </ListItemSecondaryAction>
        </ListItem>
      ))
      :
      null
  )

  handleToggle = value => (variablefromCheckboxImport) => {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]
    if(currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1)
    }

    this.setState({
      checked: newChecked
    }, () => {
      this.props.handleFiltersProp(newChecked)
    })
  }

  render() {
    return (
      <div className="collapse_items_wrapper">
        <List style={{borderBottom: '1px solid #dbdbdb'}}>
          {/* overwrite list styling from material-ui */}
          <ListItem onClick={this.handleClick} style={{padding:'10px 23px 10px 0'}}>
          {/* BRANDS, FRETS, WOOD, PRICE */}
            <ListItemText primary={this.props.title} className="collapse_title"></ListItemText>
            {this.handleAngle()}
          </ListItem>
          <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          {/* the 'in' prop determines if it is collapsed or not */}
            <List component="div" disablePadding>
              {this.renderList()}
            </List>
          </Collapse>
        </List>
      </div>
    )
  }
}

export default CollapseCheckbox