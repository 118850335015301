export const REGISTER_USER = 'register_user';
export const LOGIN_USER = 'login_user';
export const AUTH_USER = 'auth_user';
export const LOGOUT_USER = 'logout_user';
export const UPDATE_USER_DATA = 'update_user_data';
export const CLEAR_UPDATE_USER_DATA = 'clear_update_user_data';
export const ADD_TO_USER_CART = 'add_to_user_cart';
export const GET_CART_USER_ITEMS = 'get_cart_user_items';
export const REMOVE_CART_USER_ITEM = 'remove_cart-user_item';
export const ON_SUCCESS_USER_BUY = 'on_success_user_buy';

export const GET_PRODUCTS_BY_SELL = 'get_products_by_sell';
export const GET_PRODUCTS_BY_ARRIVAL = 'get_products_by_arrival';
export const GET_SERIES = 'get_series';
export const ADD_SERIES = 'add_series';
export const DELETE_SERIES = 'delete_series';
export const GET_WAVES = 'get_waves';
export const ADD_WAVE = 'add_wave';
export const DELETE_WAVE = 'delete_wave';
export const GET_PRODUCTS_TO_SHOP = 'get_products_to_shop';
export const SUBMIT_PRODUCT = 'submit_product';
export const CLEAR_PRODUCT = 'clear_product';
export const GET_PRODUCT_DETAIL = 'get_product_detail';
export const CLEAR_PRODUCT_DETAIL = 'clear_product_detail';
export const DELETE_PRODUCT = 'delete_product';